@import 'variables';
body{
  .MuiDrawer-paper{
    // background: linear-gradient(0deg,#7c52e9,#2c92ff);
  }
  .page-not-found {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background: transparent;
		text-align: center;
    img{
      width: 250px;
    }
		h2{
			font-family: $ibmplexSemiBold;
			font-size: 46px;
      line-height: 56px;
			color: #C0934F;
      margin-bottom: 25px;
		}
		p{
			font-family: $ibmplexRegular;
			font-size: 22px;
      line-height: 28px;
			color: #000000;
      margin-bottom: 40px;
		}
		a{
      text-decoration: underline;
      &.return-home{
        font-size: 24px;
        line-height:28px;
        padding: 10px 40px 12px;
        text-transform: uppercase;
        color: #ffffff !important;
			  background: #3F51B5;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        text-decoration: none;
      }
		}
	}
  .desktop-py-sidenav{
    background: #6B5EB0;
    position: fixed;
    top: 49px;
    bottom: 0;
    left: 0;
    z-index: 3;
    width: 70px;
    text-align: center;
    overflow: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar { width: 0 !important }
    nav{
      ul{
        li{
          margin-bottom: 8px;
          a{
            display: block;
            width: 80%;
            margin: 0 auto;
            padding: 8px 5px;
            img{
              width: 20px;
              margin-bottom: 4px;
            }
            p{
              @include elementStyles($size: $font12, $color: #fff, $fontFamily: $ibmplexRegular);
              line-height: 16px;
            }
            &.active{
              background: rgba(0,0,0,.2);
              border-radius: 4px;
            }
            &:hover{
              background: rgba(0,0,0,.2);
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
  .notification-menu-list{
    li.list-inline-item{
      margin-left: 20px;
      &:first-child{
        margin-left: 0;
      }
      img{
        width: 20px;
      }
    }
  }
  .desktop-header{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    border-bottom: 1px solid #d7d7d7;
    background: #fff;
    z-index: 999;
    .app-switcher{
        @include elementStyles($size: $font16, $color: #bd10e0, $fontFamily: $ibmplexMedium);
      }
    .app-logo-search{
      .app-logo{
        a{
          padding: 0 17px;
          img{
            width: 32px;
          }
        }
      }
      .search-form{
        width: 80%;
        form{
          input{
            width: 100%;
            height: 52px;
            border: 0;
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
            background: #f3f6f9;
            padding-left: 20px;
            font-size: 18px;
            font-family: $ibmplexLight;
            &:focus{
              outline: none;
            }
          }
        }
      }
    }
    .header-right-nav{
      float: right;
      >.list-inline-item{
        margin-left: 1px;
        padding: 0px 15px;
        &:first-child{
          margin-left: 0;
          border-left: none;
        }
        &:last-child{
          border-left: 1px solid #ddd;
        }
        .user-menu-dropdown{
          button{
            img{
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
            h3{
              font-size: 14px;
              line-height: 12px;
              text-transform: none;
            }
            span{
              font-size: 11px;
            }
          }
        }
      }
    }
  }
  .mobile-header{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: #fff;
    padding: 10px 0;
    border-bottom: 1px solid #d7d7d7;
    z-index: 100;
    ul{
      float: right;
      li{
        margin-left: 16px;
        a{
          img{
            width: 16px;
          }
        }
      }
    }
  }
  .notice-message{
    background: rgb(255, 243, 205);
    color: rgb(133, 100, 4);
    border: 1px solid rgb(255, 238, 186);
    text-align: center;
    padding: 5px 0px 0px;
    font-size: 13px;
    position: fixed;
    top: 48px;
    left: 0px;
    right: 0px;
  }
  .mobile-main-navs{
    width: 250px;
    // padding: 10px;
    background: #6B5EB0;
    height: 100%;
    overflow: auto;
    .user-info{
      padding: 15px;
      border-radius: 0;
      // margin-bottom: 10px;
      .user-card{
        .user-avatar{
          float: left;
          margin-right: 4px;
          height: 30px;
          width: 30px;
        }
        h3{
          @include elementStyles($size: $font16, $color: #000000, $fontFamily: $ibmplexRegular);
        }
        span{
          @include elementStyles($size: $font13, $color: #000000, $fontFamily: $ibmplexRegular);
        }
      }
    }
    .mobile-py-link{
      padding: 10px;
      background: transparent;
      ul{
        li{
          margin-bottom: 20px;
          &:last-child{
            margin-bottom: 0;
          }
          a{
            display: flex;
            align-items: center;
            cursor: pointer;

            img{
              width: 20px;
              margin-right: 10px;
            }
            p{
              display: inline;
              @include elementStyles($size: $font14, $color: #fff, $fontFamily: $ibmplexRegular);
              line-height: 16px;
            }
          }
        }
      }
    }
  }
  .secondary-sidenav{
    position: fixed;
    left: 70px;
    top: 48px;
    bottom: 0;
    width: 170px;
    background: rgba(113, 99, 186, 0.9);
    padding: 70px 6px 6px;
    height: 100%;
    z-index: 99;
    overflow: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar { width: 0 !important }
    li {
      display: block;
      width: 100%;
      margin: 0 auto;
      a{
        display: inline-block;
        width: 100%;
        cursor: pointer;
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        padding: 10px;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box;         /* Opera/IE 8+ */
        &.active{
          background: #fafafa;
          color: #000000;
          border-radius: 4px;
        }
        .folder-icon{
          position: relative;
          top: 2px;
        }
      }
    }
  }
  .mobile-secondary-drawer{
    padding: 5px 15px 2px 15px;
    background-image: linear-gradient(-180deg,#6538db,#0a56a8);
    color: #fff;
    svg{
      cursor: pointer;
    }
  }
  .mobile-secondary-manulist{
    h4{
      padding: 10px 15px;
      font-size: 16px;
      color: #f0a4ff;
      margin: 8px 0;
    }
    ul{
      li{
        a{
          display: block;
          padding-left: 32px;
          cursor: pointer;
          font-size: 12px;
          color: #fff;
          padding: 10px 15px;
          &:hover, &.active{
            background-image: linear-gradient(90deg,hsla(0,0%,100%,.5) 0,hsla(0,0%,100%,0));
          }
        }
      }
    }
  }
  footer{
    background: #191c26;
    padding: 50px 0;
    h4{
      font-size: 16px;
      color: #fff;
      font-weight: 500;
      margin: 0 0 15px 0;
      clear: both;
    }
    a{
      font-size: 13px;
      color: #fff;
      font-weight: 400;
      display: inline-block;
    }
    ul.list-unstyled{
      li{
        a{
          padding: 8px 0;
        }
        .links-footer{
          color: #bec0c7;
          text-decoration: none;
        }
        a:hover {
          color: white
        }
      }
    }
    ul.list-inline{
      margin-bottom: 15px;
      display: inline-block;
      li{
        margin-right: 15px;
        &:last-child{
          margin-right: 0;
        }
      }
    }
    .footer-copyright{
      img{
        width: 120px;
        margin-bottom: 10px;
      }
      p{
        font-size: 13px;
        font-weight: 300;
        color: #fff;
      }
    }
  }

  @media only screen and (max-width: 600px){
    .notice-message{
      z-index: 9999;
    }
  }
}
