@import 'variables';
body {
  .auth-section{
  background-position: 50%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  padding: 0px 0 15px 0;
  background-image: url('../images/auth_banner.png');
  background-size: cover;
  min-height: calc(100vh - 15px);
  position: relative;
  .auth-main-wrapper{
    background: #FFFFFF;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }
  .auth-help-text{
    h1{
      @include elementStyles($size: $font36, $fontFamily: $robotoFamily, $weight: 500, $color: $whiteColor, $margin: 0 0 20px 0);
    }
    p{
      @include elementStyles($size: $font24, $fontFamily: $robotoFamily, $weight: 300, $color: $whiteColor, $margin: 0 0 20px 0);
      opacity: 0.63
    }
  }
  .auth-left-blk{
    background: #fff;

    border-radius: 20px 0px 0px 20px;
    .sparta-logo{
      width: 50px;
      margin: 15px 0 0 15px;
    }
    .auth-cta {
    float: right;
   .list-inline-item-solutions{
     border: 1px solid #3f51b5 ;
     a{
     color: #3f51b5;

     }
   }
    .cta{
     
      padding: 3px;
      a {

        text-decoration:none;
        color:$whiteColor;
      }
      text-decoration:none;
      color:$whiteColor;
    }
    }
    .auth-form-wrapper{
      padding: 50px;
      &.lessor-selection-from{
        margin-top: 50px;
      }
      a{
        @include elementStyles($size: 12px, $fontFamily: $robotoFamily, $weight: 400, $color: #0093ff, $margin: 0 0 10px 0);
        display: inline-block;
      }
      .form-title{
        @include elementStyles($size: 24px, $fontFamily: $ibmplexMedium, $weight: 400, $color: #000000, $margin: 0 0 10px 0);
      }
      .form-para{
        @include elementStyles($size: 14px, $fontFamily: $robotoFamily, $weight: 400, $color: #595959, $margin: 0 0 40px 0);
        &.otp{
          font-size: 13px;
          font-weight: bold;
          margin-top: 16px;
        }
      }
      .terms-and-condition{
        margin: 10px 0;
        clear: both;
        @include elementStyles($size: $font13, $fontFamily: $ibmplexRegular, $color: #010101, $margin: 20px 0 20px 0);

        .url{
          margin: auto;
          font-size: 13px;
          line-height: 1.5;
        }
      }
      form{
        .forgot-pwd-link{
          @include elementStyles($size: $font13, $fontFamily: $robotoFamily, $weight: 400, $color: #0093ff, $margin: 0px 0 5px 0);
          float: right;
        }
        .terms-policy{
          @include elementStyles($size: $font13, $fontFamily: $robotoFamily, $weight: 400, $color: #010101, $margin: 20px 0 20px 0);
          clear: both;
          a{
            color: #0093ff;
          }
        }
        input[type="submit"]{
          @include elementStyles($size: $font14, $fontFamily: $robotoFamily, $weight: 400, $color: $whiteColor, $margin: 10px 0 0 0, $padding: 15px);
          width: 200px;
          line-height: normal;
          letter-spacing: .7px;
          background: #2f8cff;
          border: 1px solid #2670ca;
          border-radius: 3px;
          cursor: pointer;
        }
      }
      .MuiRadio-colorSecondary.Mui-checked{
        color: #3f51b5;
      }
      .MuiInputAdornment-root{
        .MuiSvgIcon-root{
          font-size: 18px;
        }
      }
      .email-textfield{
        margin-bottom: 37px;
      }
    }
  }
  .img-slider-wrapper{
    background: #C3EAFF;
    border-radius: 0px 20px 20px 0px;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 50px;
    height: 550px;
    .application-info-card{
      img{
        margin: auto;
        width: 280px;
        object-fit: contain;
        &.commverge, &.fin-s{
          width: 260px;
        }
      }
      h4{
        @include elementStyles(24px, #010101, normal, normal, $ibmplexRegular, null, null);
        margin: 20px 0 15px 0;
      }
      p{
        @include elementStyles(16px, #010101, normal, normal, $ibmplexRegular, null, null);
      }
    }
    .image-slider{
      .slick-dots{
        li{
          button{
            &::before{
              font-size: 10px;
            }
          }
        }
      }
    }
  }
  .auth-right-blk{
    background: #C3EAFF;
    border-radius: 0px 20px 20px 0px;
    height: 100%;
    overflow: hidden;
    .image-slider{
      padding:50px 30px;
      .slick-slide{
        img{
          margin: auto;
          width: 280px;
          margin-bottom: 60px;
          &.fin-s, &.commverge{
            width: 240px;
          }
        }
        .img-title{
          @include elementStyles($size: 24px, $fontFamily: $ibmplexRegular, $color: #010101, $margin: 20px 0 15px 0);
        }
        p{
          @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #010101);
          line-height: 24px;
          letter-spacing: 1px;
        }
        .list-unstyled{
          li{
            font-size: 13px;
            padding-left: 10px;

            &::before{
              content: "";
              position: absolute;
              width: 4px;
              height: 4px;
              background-color: #000000;
              border-radius: 50%;
              left: 0;
              top: 7px;
            }
          }
        }
      }
      .slick-dots{
        bottom: 25px;
        left: 0;
        li{
          button{
            &::before{
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
  .user-management-module-section {
    //user detail list
    .user-detail {
      .section-1 {
        height: 160px;
        position: relative;
        .profile-pic{
          width: 110px;
          height: 110px;
          margin: 20px 0px 0px 34px;
          background-size: cover;
          border-radius: 50%;
          transition: .3s ;
          position: relative;
          .edit-profile-pic-avatar{
            display:none;
            position:relative;
            top:10px;
            right: 40px;
          }
          .edit-profile-pic {
            display:none;
            position:absolute;
            top:43px;
            left: 40px
          }
          &:hover {
            transform: scale(1.1);
          }
          &:hover .edit-profile-pic{
            display: block;

          }


        }


        .list-basic-data {
          position: absolute;
          top: 1px;
          left: 160px;
          top: 15px;
          .list-item{
            font-size: 12px;
            font-weight: 100;
          }
          .user-name{
            position: absolute;
            font-size: 14px;
          }
        }
        .btn-user-detail{
          float:right;
        }
        .resume{
          margin-top: 37px;
        }
      }
      .resumeNew {
        position: relative;
        right: 6rem;
        top: 2rem;
        float: right;
      }
      .resumeOld {
        position: relative;
        right: -3rem;
        top: 4rem;
        float: right;
      }
      .section-2{
        margin-right: 20px;
        .section-2-list {
          margin: 20px 0px 20px 0px;
        }
      }
    }
    .buttons-cta{
      float:right;
      margin-bottom: 30px;
    }
    .cta-mouse{
      cursor: pointer;
    }
  }

}
